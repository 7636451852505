<!--
 * @Author: George
 * @Description: 个人信息表 - 子女详情页
-->
<template>
    <!-- 回显信息 -->
    <div ref="userDetail" class="details-module common-part">
        <!-- 主申请人信息 -->
        <el-row :gutter="15">
            <el-col :span="12" id="company_name">
                <span>用人单位：</span>
                <span>{{ currentItem.company_name || "-" }}</span>
            </el-col>
            <el-col :span="12" id="career">
                <span>职业：</span>
                <span>{{ currentItem.career || "-" }}</span>
            </el-col>
            <el-col :span="12" id="company_type">
                <span>界别：</span>
                <span>{{ currentItem.company_type || "-" }}</span>
            </el-col>
            <el-col :span="12" id="company_type_other">
                <template v-if="currentItem.company_type === '其它 （请注明）'">
                    <span>其它 （请注明）：</span>
                    <span>{{ currentItem.company_type_other || "-" }}</span>
                </template>
            </el-col>
            <el-col :span="12" id="entry_time">
                <span>入职年月：</span>
                <span>{{ currentItem.entry_time || "-" }}</span>
            </el-col>
            <el-col :span="12" id="departure_time">
                <span>离职年月：</span>
                <span>{{ currentItem.work_to_now != 1 ? (currentItem.departure_time || '-') : '至今' }}</span>
            </el-col>
            <el-col :span="12" id="payroll_unit_location">
                <span>发薪单位地点：</span>
                <span>{{ payrollUnitLocation(currentItem.payroll_unit_location) }}</span>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import CONFIG from "../../config"

export default {
    props: {
        currentItem: {
            type: Object,
            default() {
                return {}
            },
        },
    },
    data() {
        return {
            CONFIG,
        }
    },
    methods: {
        payrollUnitLocation(location) {
            let _location = location
            if (_location && _location.country) {
                if (_location.country.includes('中国')) {
                    return `${_location.country}${_location.area.join('')}`
                } else {
                    return `${_location.country}${_location.foreign}`
                }
            }
            return '-'
        }
    }
};
</script>

<style lang="less" scoped>
.declaration {
    align-items: flex-start !important;
}
</style>
